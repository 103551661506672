import React from "react";

import Container from "@material-ui/core/Container";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import { makeStyles } from "@material-ui/core/styles";

import MainNavigation from "./MainNavigation";
import FontAwesomeIcon from "./FontAwesomeIcon";

export const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  title: {
    flexGrow: 1,
    fontSize: "1.1rem",
    fontWeight: "400",
  },
  toolbar: {
    minHeight: "45px",
  },
}));

const Header = ({ isSignedIn }) => {
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (isOpen) => (event) => {
    if (!isSignedIn) {
      return;
    }

    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(isOpen);
  };

  return (
    <AppBar position="static" color="inherit">
      <Container component="main" maxWidth="sm" className={classes.root}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <FontAwesomeIcon iconName="bars" />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            TheDailyToDo
          </Typography>
        </Toolbar>
        {isSignedIn && (
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            variant="temporary"
          >
            <div
              className={classes.list}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <MainNavigation />
            </div>
          </Drawer>
        )}
      </Container>
    </AppBar>
  );
};

export default Header;
