import { createMuiTheme } from "@material-ui/core/styles";

export const APP_THEME = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      // light: "#bb6bc9",
      // main: "#ab47bc",
      //main: "#ec407a",
      main: "#7e3ff2",
      // dark: "#773183",
      contrastText: "#FFFFFF",
    },
    secondary: {
      // light: "#e6e6e6",
      main: "#E0E0E0",
      // dark: "#9c9c9c",
      contrastText: "#000000",
    },
    background: {
      paper: "#424242",
      default: "#212121",
    },
  },
  body: {
    marginBottom: "20px",
  },
  container: {
    padding: "16px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: "0",
    marginBottom: "20px",
  },
  typography: {
    h1: {
      fontSize: "1.6rem",
      marginBottom: "12px",
    },
    h2: {
      fontSize: "1.4rem",
      marginBottom: "12px",
    },
    h3: {
      fontSize: "1.2rem",
      marginBottom: "12px",
    },
    h4: {
      fontSize: "1.1rem",
      marginBottom: "12px",
    },
    h5: {
      fontSize: "1rem",
      marginBottom: "12px",
    },
  },
  a: {
    textDecoration: "none",
  },
});
